import React, {useState} from 'react'
import {
  styled,
  FormControl as MuiFormControl,
  IconButton as MuiIconButton,
  OutlinedInput as MuiOutlinedInput,
  InputAdornment as MuiInputAdornment,
  InputLabel as MuiInputLabel,
} from '@material-ui/core'
import MuiCloseIcon from '@material-ui/icons/Close'
import {useLabelWidth} from 'ui/hooks'

const FormControl = styled(MuiFormControl)(({theme}) => ({
  marginRight: theme.spacing(2),
  width: 400,
}))

const ClearButton = (props) => {
  return (
    <MuiInputAdornment position="end">
      <MuiIconButton size="small" {...props}>
        <MuiCloseIcon size="small"/>
      </MuiIconButton>
    </MuiInputAdornment>
  )
}

const Search = ({onChange, ...props}) => {
  const [labelWidth, labelRef] = useLabelWidth()
  const [search, setSearch] = useState("")

  const changeSearch = (search) => {
    setSearch(search)
    onChange(search)
  }

  return (
    <FormControl variant="outlined" margin="dense" {...props}>
      <MuiInputLabel htmlFor="search" ref={labelRef}>Search</MuiInputLabel>
      <MuiOutlinedInput
        id="search"
        labelWidth={labelWidth}
        value={search}
        onChange={e => changeSearch(e.target.value)}
        endAdornment={<ClearButton onClick={() => changeSearch("")}/>}
      />
    </FormControl>
  )
}

export default Search
