import React from 'react'
import {
  styled,
} from '@material-ui/core'

const Container = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'center',
}))

const Image = styled('img')(({theme}) => ({
  objectFit: 'contain',
  height: 100,
  maxWidth: '100%',
}))

const Logo = ({src, ...props}) => {
  if (!src) return null

  return (
    <Container>
      <Image src={src}/>
    </Container>
  )
}

export default Logo
