import {connectRouter, routerMiddleware} from 'connected-react-router'
import history from 'utils/history'
import appReducer from './reducer'

const routerReducer = connectRouter(history)

const getAppModule = () => ({
  id: 'app',
  reducerMap: {
    app: appReducer,
    router: routerReducer,
  },
  middlewares: [
    routerMiddleware(history)
  ],
  sagas: [],
})

export default getAppModule
