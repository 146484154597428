import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiContainer from '@material-ui/core/Container'
import MuiPaper from '@material-ui/core/Paper'

const StyledContainer = styled(MuiContainer)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    minHeight: '100vh',
  }
}))

const StyledPaper = styled(MuiPaper)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    margin: '50px 20px',
    borderTopWidth: 8,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.primary.dark,
  },

  [theme.breakpoints.up('md')]: {
    margin: '100px 0',
  },
}))

const Layout = ({children, ...props}) => {
  return (
    <StyledContainer fixed {...props}>
      <StyledPaper square>
        {children}
      </StyledPaper>
    </StyledContainer>
  )
}

export default Layout
