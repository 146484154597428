import React from 'react'
import {styled} from '@material-ui/core/styles'

const Content = styled('div')({
  position: 'relative',
  height: '100vh',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const Layout = ({children, ...props}) => {
  return (
    <Content {...props}>
      {children}
    </Content>
  )
}

export default Layout
