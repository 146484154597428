import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import MuiDeleteIcon from '@material-ui/icons/DeleteRounded'

const styles = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  iconHover: {
    margin: theme.spacing(2),
    '&:hover': {
      color: red[800],
      cursor: 'pointer'
    }
  }
})

const DeleteIcon = props => {
  const {classes} = props
  return (
    <div className={classes.root}>
      <MuiDeleteIcon className={classes.iconHover} onClick={props.onClick}/>
    </div>
  )
}

DeleteIcon.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(DeleteIcon)
