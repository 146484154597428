import leadsReducer from './reducer'
import leadsSagas from './sagas'

const getLeadsModule = () => ({
  id: 'leads',
  reducerMap: {
    leads: leadsReducer,
  },
  sagas: [
    leadsSagas,
  ],
  initialActions: [],
  finalActions: [],
})

export default getLeadsModule
