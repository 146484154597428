import usersReducer from './reducer'
import usersSagas from './sagas'
import {login} from './actions'

const getUsersModule = () => ({
  id: 'users',
  reducerMap: {
    users: usersReducer,
  },
  sagas: [
    usersSagas,
  ],
  initialActions: [
    login(),
  ],
  finalActions: [],
})

export default getUsersModule
