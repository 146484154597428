import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { styled } from '@material-ui/core/styles'
import MuiAvatar from '@material-ui/core/Avatar'
import MuiButton from '@material-ui/core/Button'
import MuiAccountCircleIcon from '@material-ui/icons/AccountCircle'
import MuiMenu from '@material-ui/core/Menu'
import MuiMenuItem from '@material-ui/core/MenuItem'
import { useCurrentUser } from 'users/hooks'
import history from 'utils/history'

const Button = styled(MuiButton)({
  fontSize: 16,
  fontWeight: 400,
  textTransform: 'none',
  borderRadius: 0
})

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: 30,
  height: 30,
  marginLeft: theme.spacing(1)
}))

const AccountIcon = styled(MuiAccountCircleIcon)(({ theme }) => ({
  fontSize: 36,
  marginLeft: theme.spacing(1)
}))

const MenuItem = styled(MuiMenuItem)({
  padding: 0,
  minHeight: 'auto'
})

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
  color: theme.palette.text.primary,
  minWidth: 200,
  width: '100%',
  padding: '5px 10px'
}))

const SignupButton = styled(MuiButton)({
  boxShadow: 'none',
  textTransform: 'none'
})

const LoginButton = styled(MuiButton)({
  boxShadow: 'none',
  textTransform: 'none',
  marginRight: '1rem'
})

const GuestUserActionContainer = styled('div')({
  margin: '.5rem 0'
})

const UserMenu = props => {
  const currentUser = useCurrentUser()
  const [anchorEl, setAnchorEl] = useState(null)
  const isGuestUser = !currentUser

  const handleClick = e => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const goToLoginPage = () => {
    history.push('/login')
  }

  const goToRegisterPage = () => {
    history.push('/register')
  }

  let firstName, lastName, fullName, hasUserRole
  if (currentUser) {
    firstName = currentUser.firstName || ''
    lastName = currentUser.lastName || ''
    fullName = `${firstName} ${lastName}`
    hasUserRole = currentUser.role === 'user'
  }

  let avatar
  if (!isGuestUser && currentUser.avatar) {
    avatar = <Avatar alt={fullName} src={currentUser.avatar.uri} />
  } else {
    avatar = <AccountIcon />
  }

  if (!isGuestUser) {
    return (
      <>
        <Button color='primary' onClick={handleClick}>
          {fullName}
          {avatar}
        </Button>
        <MuiMenu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <MenuItem onClick={handleClose}>
            <StyledLink to='/profile'>Profile</StyledLink>
          </MenuItem>
          {!hasUserRole && (
            <MenuItem onClick={handleClose}>
              <StyledLink to='/settings'>Settings</StyledLink>
            </MenuItem>
          )}
          <MenuItem onClick={handleClose}>
            <StyledLink to='/logout'>Logout</StyledLink>
          </MenuItem>
        </MuiMenu>
      </>
    )
  } else {
    return (
      <GuestUserActionContainer>
        <LoginButton onClick={goToLoginPage}>Log in</LoginButton>
        <SignupButton
          onClick={goToRegisterPage}
          color='primary'
          variant='contained'
        >
          Sign Up
        </SignupButton>
      </GuestUserActionContainer>
    )
  }
}

export default UserMenu
