import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiContainer from '@material-ui/core/Container'
import UserMenu from 'users/components/UserMenu'
import NavMenu from './NavMenu'
import HomeLink from './HomeLink'
import AdminMenu from './AdminMenu'
import {useCurrentUser} from 'users/hooks'

const Content = styled('header')(({theme}) => ({
  flex: '0 0 auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2)',
  position: 'sticky',
  top: 0,
  zIndex: 1000,
}))

const Container = styled(MuiContainer)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
})

const StyledHomeLink = styled(HomeLink)({
  alignSelf: 'center',
})

const StyledNavMenu = styled(NavMenu)({
  flexGrow: 1,
  marginLeft: 10,
})

const Dashboard = ({children, ...props}) => {
  const currentUser = useCurrentUser()
  const isGuestUser = !currentUser

  const isAdmin = currentUser && currentUser.role === 'admin'

  return (
    <Content {...props}>
      <Container>
        <StyledHomeLink/>
        {!isGuestUser && <StyledNavMenu/>}
        {isAdmin ? <AdminMenu/> : null}
        <UserMenu/>
      </Container>

      {children}
    </Content>
  )
}

export default Dashboard
