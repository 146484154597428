import React from 'react'
import {useDispatch} from 'react-redux'
import {
  styled,
  Button as MuiButton,
} from '@material-ui/core'
import {sendPasswordReset} from 'users/store/actions'

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center',
})

const Accept = ({email, ...props}) => {
  const dispatchRedux = useDispatch()

  const handleAccept = (event) => {
    event.preventDefault()

    dispatchRedux(sendPasswordReset(email, {newUser: true}))
  }

  return (
    <Actions {...props}>
      <MuiButton
        onClick={handleAccept}
        color="primary"
        variant="contained"
      >
        Complete My Registration
      </MuiButton>
    </Actions>
  )
}

export default Accept
