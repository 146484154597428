import {styled} from '@material-ui/core'

const Values = styled('div')(({theme}) => ({
  fontSize: 12,
  textAlign: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}))

export default Values
