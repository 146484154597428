import React, { useState } from 'react'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import {
  styled,
  Button as MuiButton,
  TextField as MuiTextField
} from '@material-ui/core'
import { updatePassword } from 'users/store/actions'
import { usePasswordState } from 'users/hooks'

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column'
})

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main
}))

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center'
})

export const tokenEntrySchema = yup
  .string()
  .matches(/[0-9]/, { excludeEmptyString: true })
  .max(6)
  .trim()
export const tokenFinalSchema = yup
  .string()
  .label('Code')
  .matches(/[0-9]/, 'You must enter the 6-digit code found in your email')
  .length(6)
  .required('You must enter the 6-digit code found in your email')

export const passwordSchema = yup
  .string()
  .label('Password')
  .min(4)
  .required('A password is required')

export const formSchema = yup.object().shape({
  token: tokenFinalSchema,
  password: passwordSchema
})

const validateForm = (token, password) => {
  const errors = {}

  try {
    formSchema.validateSync({ token, password }, { abortEarly: false })
  } catch ({ inner }) {
    inner.forEach(e => (errors[e.path] = e.message))
  }

  return errors
}

const SetPasswordForm = ({ email, buttonTitle }) => {
  const { status } = usePasswordState()
  const [token, setToken] = useState('')
  const [password, setPassword] = useState()
  const [showErrors, setShowErrors] = useState(false)
  const dispatchRedux = useDispatch()

  const errors = validateForm(token, password)

  const disableButton = status === 'started' || status === 'completed'

  let invalidCodeError
  if (status === 'failed') {
    invalidCodeError = (
      <ErrorMessage>
        The code you entered was either invalid or has expired. Please check
        your email for a new code.
      </ErrorMessage>
    )
  }

  const handleTokenChange = async event => {
    try {
      const newToken = await tokenEntrySchema.validate(event.target.value)
      setToken(newToken)
    } catch (error) {
      // drop invalid characters
    }
  }

  const handlePasswordChange = e => setPassword(e.target.value)

  const handleSubmit = event => {
    event.preventDefault()

    if (errors.password) {
      setShowErrors(true)
    } else {
      dispatchRedux(updatePassword({ email, token, password }))
    }
  }

  let tokenError, passwordError
  if (showErrors) {
    tokenError = errors.token
    passwordError = errors.password
  }

  return (
    <Form onSubmit={handleSubmit}>
      {invalidCodeError}
      <MuiTextField
        label='Enter 6-digit code'
        type='text'
        value={token}
        onChange={handleTokenChange}
        error={!!tokenError}
        helperText={tokenError}
        variant='outlined'
        margin='dense'
        fullWidth
        autoFocus
      />
      <MuiTextField
        label='Create a password'
        type='password'
        defaultValue=''
        onChange={handlePasswordChange}
        error={!!passwordError}
        helperText={passwordError}
        variant='outlined'
        margin='dense'
        fullWidth
      />
      <Actions>
        <MuiButton
          onClick={handleSubmit}
          color='primary'
          variant='contained'
          disabled={disableButton}
        >
          {buttonTitle}
        </MuiButton>
      </Actions>
    </Form>
  )
}

export default SetPasswordForm
