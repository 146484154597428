import React from 'react'
import {useCurrentUser} from 'users/hooks'
import GuestBlockModal from 'ui/components/Modals/GuestUserBlockModal'
import {withCookies} from 'react-cookie'

const MAX_GUEST_USER_DETAIL_PAGE_VIEWS = 4
const MAX_GUEST_USER_SEARCH_COUNT = 3
const MAX_GUEST_USER_COMPARE_COUNT = 3

const GuestBlock = props => {
  const {cookies} = props

  const comparisonPageViews = Number(cookies.get('guestCompareViews'))
  const detailPageViews = Number(cookies.get('guestApplicationViews'))
  const searchCount = Number(cookies.get('guestSearchCount'))

  const user = useCurrentUser()
  const isGuestUser = !user

  const hasGuesUserReachedLimitations =
    isGuestUser &&
    (comparisonPageViews >= MAX_GUEST_USER_COMPARE_COUNT ||
      searchCount >= MAX_GUEST_USER_SEARCH_COUNT ||
      detailPageViews >= MAX_GUEST_USER_DETAIL_PAGE_VIEWS)

  return isGuestUser && <GuestBlockModal open={hasGuesUserReachedLimitations} />
}

export default withCookies(GuestBlock)
