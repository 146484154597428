import React, { useReducer } from 'react'
import {
  styled,
  IconButton as MuiIconButton,
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent
} from '@material-ui/core'
import MuiCloseIcon from '@material-ui/icons/Close'
import { useSelector } from 'react-redux'
import { getMessages } from 'app/store/selectors'
import { initialState, reducer } from './state'

const SnackbarContent = styled(MuiSnackbarContent)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.success.main
}))

const MessageBar = props => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const messages = useSelector(getMessages)

  if (messages.length > state.index) {
    dispatch({ type: 'OPEN' })
  }

  const handleClose = () => {
    dispatch({ type: 'CLOSE' })
  }

  const messageText = messages[0] && messages[0].text
  const isError = messages[0] && messages[0].isError
  const closeAction = (
    <MuiIconButton onClick={handleClose} color='inherit'>
      <MuiCloseIcon />
    </MuiIconButton>
  )

  return (
    <MuiSnackbar
      key={state.index}
      open={state.open}
      onClose={handleClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <SnackbarContent
        message={messageText}
        action={closeAction}
        style={{ backgroundColor: isError ? 'red' : '' }}
      />
    </MuiSnackbar>
  )
}

export default React.memo(MessageBar)
