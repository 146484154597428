import React from 'react'
import {Redirect} from 'react-router-dom'
import queryString from 'query-string'
import {usePasswordState} from 'users/hooks'
import {
  Layout,
  Body,
  Info,
  Main,
} from 'ui/components/DividedLayout'
import PasswordReset from './PasswordReset'
import RequestPasswordReset from './RequestPasswordReset'

const Password = (props) => {
  const {token, source} = queryString.parse(props.location.search)
  const {status} = usePasswordState()

  if (status === 'completed') return <Redirect to="/"/>

  let content = <RequestPasswordReset/>

  if (token && status !== 'failed') {
    content = <PasswordReset token={token} source={source}/>
  }

  return (
    <Layout>
      <Body>
        <Info />
        <Main>
          {content}
        </Main>
      </Body>
    </Layout>
  )
}

export default Password
