import React from 'react'
import {styled} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'

const Content = styled('div')(({theme}) => ({
  display: 'flex',
}))

const Text = styled('p')(({theme}) => ({
  margin: 0,
}))

const StyledLink = styled(Link)(({theme}) => ({
  color: theme.palette.secondary.main,
  marginLeft: '0.28em',
}))

const SwitchToLogin = (props) => {
  return (
    <Content>
      <Text>Already have an account?</Text>
      <StyledLink to="/login">Sign in</StyledLink>
    </Content>
  )
}

export default SwitchToLogin
