import {
  useEffect,
  useRef,
  useState,
} from 'react'

export const useLabelWidth = (labelText="") => {
  const labelRef = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)

  useEffect(() => {
    if (labelRef.current) setLabelWidth(labelRef.current.offsetWidth)
  }, [labelText])

  return [labelWidth, labelRef]
}
