import mirrorCreator from 'mirror-creator'

export const actionTypes = mirrorCreator([
  'LOGIN_REQUESTED',
  'LOGIN_SUCCEEDED',
  'LOGIN_FAILED',

  'LOGOUT_REQUESTED',
		'LOGOUT_COMPLETED',
		
		'RESET_USER_STATE',

  'CREATE_USER_REQUESTED',
  'CREATE_USER_SUCCEEDED',
  'CREATE_USER_FAILED',

  'SEND_PASSWORD_RESET',
  'SEND_INVITE_EMAIL',

  'UPDATE_PASSWORD_REQUESTED',
  'UPDATE_PASSWORD_SUCCEEDED',
  'UPDATE_PASSWORD_FAILED',

  'ADD_LMS_TO_LIST',
  'REMOVE_LMS_FROM_LIST',

  'REFETCH_CURRENT_USER_REQUESTED',

  'UPDATE_CURRENT_USER_REQUESTED',
  'UPDATE_CURRENT_USER_SUCCEEDED',
  'UPDATE_CURRENT_USER_FAILED',

  'FETCH_USERS_REQUESTED',
  'FETCH_USERS_SUCCEEDED',
  'FETCH_USERS_FAILED',

  'PATCH_USER_REQUESTED',
  'PATCH_USER_SUCCEEDED',
  'PATCH_USER_FAILED',

  'REMOVE_USER_REQUESTED',
  'REMOVE_USER_SUCCEEDED',
  'REMOVE_USER_FAILED',

		'UPDATE_VENDOR_ADMINS',
		'UPDATE_GUEST_COOKIE_CONSENT'
], {prefix: 'users/'})
