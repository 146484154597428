import React from 'react'
import {Redirect} from 'react-router-dom'
import {DynamicModuleLoader} from 'redux-dynamic-modules'
import getReportsModule from 'reports/store/module'
import {useMode} from 'app/hooks'
import AdminDashboard from './Admin'
import VendorDashboard from './Vendor'
import getLeadsModule from 'leads/store/module'

const Dashboard = () => {
  const [mode] = useMode()

  let dashboard
  switch (mode) {
    case 'admin':
      dashboard = <AdminDashboard/>
      break

    case 'vendor':
      dashboard = <VendorDashboard/>
      break

    case 'user':
    default:
      return <Redirect to="/"/>
  }

  return (
    <DynamicModuleLoader modules={[getReportsModule(), getLeadsModule()]}>
      {dashboard}
    </DynamicModuleLoader>
  )
}

export default Dashboard
