import React, {useState} from 'react'
import {
  styled,
  Button as MuiButton,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
} from '@material-ui/core'
import MuiCodeIcon from '@material-ui/icons/Code'
import MuiCheckIcon from '@material-ui/icons/Check'
import {useCurrentUser} from 'users/hooks'
import {useMode} from 'app/hooks'

const Button = styled(MuiButton)({
  fontSize: 16,
  borderRadius: 0,
})

const MenuItem = styled(MuiMenuItem)({
})

const InsetMenuItem = styled(MuiMenuItem)({
  paddingLeft: 32,
  minWidth: 200,
  display: 'flex',
  justifyContent: 'space-between',
})

const AdminMenu = (props) => {
  const [mode, setMode] = useMode()
  const currentUser = useCurrentUser()
  const [anchorEl, setAnchorEl] = useState(null)

  if (currentUser.role !== 'admin') return null

  const openMenu = e => setAnchorEl(e.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const handleSetMode = (newMode) => {
    setMode(newMode)
    closeMenu()
  }

  return (
    <>
      <Button
        color="primary"
        onClick={openMenu}
      >
        <MuiCodeIcon/>
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem disabled>
          Mode
        </MenuItem>
        <InsetMenuItem onClick={() => handleSetMode('admin')}>
          Admin
          {mode === 'admin' ? <MuiCheckIcon/>: null}
        </InsetMenuItem>
        <InsetMenuItem onClick={() => handleSetMode('vendor')}>
          Vendor
          {mode === 'vendor' ? <MuiCheckIcon/> : null}
        </InsetMenuItem>
        <InsetMenuItem onClick={() => handleSetMode('user')}>
          User
          {mode === 'user' ? <MuiCheckIcon/> : null}
        </InsetMenuItem>
      </MuiMenu>
    </>
  )
}

export default AdminMenu
