import {actionTypes} from './constants'

export const setMode = (mode) => ({
  type: actionTypes.SET_MODE,
  payload: {
    mode,
  }
})

export const postMessage = ({message, isError}) => ({
  type: actionTypes.POST_MESSAGE,
  payload: {
				text: message,
				isError
  },
})
