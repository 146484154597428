import produce from 'immer'
import {actionTypes} from './constants'

const initialState = {
  mode: 'User',
  messages: [],
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.SET_MODE:
      draft.mode = action.payload.mode
      break

    case actionTypes.POST_MESSAGE:
      draft.messages.unshift(action.payload)
      break

    // no default
  }
}, initialState)

export default reducer
