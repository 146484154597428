import React, {useState} from 'react'
import {withRouter} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core'
import MuiDeleteIcon from '@material-ui/icons/Delete'
import {removeUser} from 'users/store/actions'

const DeleteIcon = styled(MuiDeleteIcon)(({theme}) => ({
  marginRight: theme.spacing(1),
}))

const DialogContent = styled(MuiDialogContent)(({theme}) => ({
  width: 400,
  textAlign: 'center',
  fontSize: 18,
}))

const DeleteButton = ({id, staticContext, history, ...props}) => {
  const [open, setOpen] = useState(false)
  const dispatchRedux = useDispatch()

  if (!id) return null

  const handleDelete = () => {
    dispatchRedux(removeUser(id))
    history.push('/users')
  }

  const handleOpenDialog = () => setOpen(true)
  const handleCloseDialog = () => setOpen(false)

  return (
    <>
      <MuiButton
        size="small"
        onClick={handleOpenDialog}
        {...props}
      >
        <DeleteIcon size="small"/>
        Delete User
      </MuiButton>
      <MuiDialog
        open={open}
        onClose={handleCloseDialog}
      >
        <MuiDialogTitle>Are you sure?</MuiDialogTitle>
        <DialogContent>
          This cannot be undone!
        </DialogContent>
        <MuiDialogActions>
          <MuiButton onClick={handleCloseDialog}>Cancel</MuiButton>
          <MuiButton color="primary" onClick={handleDelete}>Delete User</MuiButton>
        </MuiDialogActions>
      </MuiDialog>
    </>
  )
}

export default withRouter(DeleteButton)
