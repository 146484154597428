import React from 'react'
import {Route, Redirect} from 'react-router-dom'
import {useMode} from 'app/hooks'

const AdminRoute = ({redirect = "/", ...props}) => {
  const [mode] = useMode()

  return mode === 'admin' ? <Route {...props} /> : <Redirect to={redirect}/>
}

export default AdminRoute
