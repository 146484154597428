import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {
  withStyles,
  styled,
  Button as MuiButton,
  Paper as MuiPaper,
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel
} from '@material-ui/core'
import {useCurrentUser} from 'users/hooks'
import {updateCurrentUser} from 'users/store/actions'

const Content = styled(MuiPaper)({
  marginTop: 20,
  padding: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start'
})

const Title = styled('h2')({
  margin: 0,
  marginBottom: 20
})

const SubTitle = styled('h4')({
  margin: 0,
  marginBottom: 20
})

const Label = withStyles(theme => ({
  root: {
    marginLeft: 0
  },
  label: {
    fontSize: 14,
    fontWeight: 500
  }
}))(MuiFormControlLabel)

const Form = styled('form')(({theme}) => ({
  maxWidth: 800
}))

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'center'
})

const UpdateButton = styled(MuiButton)({})

const Settings = props => {
  const currentUser = useCurrentUser()
  const [user, setUser] = useState(currentUser)
  const dispatchRedux = useDispatch()
  const [leadNotifications, setLeadNotifications] = useState(
    currentUser.settings ? Boolean(currentUser.settings.leadNotifications) : false
  )

  const updateUser = () => {
    setLeadNotifications(Boolean(!leadNotifications))
    const settingsToUpdate = user.settings || {}
    const updatedUser = {
      ...user,
      settings: {
        ...settingsToUpdate,
        leadNotifications: !leadNotifications
      }
    }
    setUser(updatedUser)
  }

  const handleSubmit = () => {
    const message = 'Settings successfully updated'
    dispatchRedux(updateCurrentUser(user, message))
  }
  const checkbox = <MuiCheckbox checked={leadNotifications} />

  return (
    <Content>
      <Form onSubmit={handleSubmit}>
        <Title>Settings</Title>
        <SubTitle>Notifications</SubTitle>
        <Label
          label={<p>Notify me when a new lead is generated<span style={{color: '#808080'}}> (Only applies to Premium Systems)</span></p>}
          control={checkbox}
          onClick={updateUser}
          disabled={false}
          {...props}
        />
        <Actions>
          <UpdateButton
            color='primary'
            variant='contained'
            onClick={handleSubmit}
          >
            Update
          </UpdateButton>
        </Actions>
      </Form>
    </Content>
  )
}

export default Settings
