import {useSelector, useDispatch} from 'react-redux'
import {
  getCurrentSearchFilters,
  getCurrentSearchResults,
  getSearchesData,
} from 'searches/store/selectors'
import {fetchSearches} from 'searches/store/actions'

export const useCurrentSearchFilters = () => {
  const filters = useSelector(getCurrentSearchFilters)

  return filters
}

export const useCurrentSearchResults = () => {
  const results = useSelector(getCurrentSearchResults)

  return results
}

export const useSearchesData = () => {
  const results = useSelector(getSearchesData)
  const dispatchRedux = useDispatch()

  if (!results.status) dispatchRedux(fetchSearches())

  return results
}
