import React from 'react'
import {styled} from '@material-ui/core/styles'
import {useCurrentUser} from 'users/hooks'
import SetPasswordForm from './Form'

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
  marginBottom: 6,
})

const Subtitle = styled('h4')({
  fontSize: 16,
  fontWeight: 'normal',
  marginTop: 0,
  marginBottom: 18,
})

const PasswordReset = (props) => {
  const {email} = useCurrentUser()

  return (
    <>
      <Title>Complete Your Registration</Title>
      <Subtitle>Check your email for a code to complete your registration</Subtitle>
      <SetPasswordForm email={email} buttonTitle="Complete Registration"/>
    </>
  )
}

export default PasswordReset
