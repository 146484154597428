import reportsReducer from './reducer'
import reportsSagas from './sagas'

const getReportsModule = () => ({
  id: 'reports',
  reducerMap: {
    reports: reportsReducer,
  },
  middlewares: [],
  sagas: [
    reportsSagas,
  ],
})

export default getReportsModule
