import React from 'react'
import {styled} from '@material-ui/core/styles'

const Content = styled('main')({
  flex: '1 1 auto',
  position: 'relative',
  overflow: 'auto',
})

const Main = ({children, ...props}) => {
  return (
    <Content {...props}>
      {children}
    </Content>
  )
}

export default Main
