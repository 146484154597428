import React from 'react'
import { styled } from '@material-ui/core'
import { useCompareData } from './hooks'
import { Section, Subsection } from './Sections'
import Row from './Row'
import Values from './Values'
import { HIDDEN_FEATURES } from 'constants/hiddenFeatures'

const List = styled('ul')(({ theme }) => ({
  fontSize: 12,
  margin: 0,
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(2)
}))

const ListItem = styled('li')(({ theme }) => ({
  whiteSpace: 'pre-line',
  marginBottom: theme.spacing(0.5)
}))

const Features = props => {
  const { systems, featuresData } = useCompareData()

  const checkIfVisible = feature =>
    feature && !HIDDEN_FEATURES.includes(feature.name)
  const checkIfChildFeatureIsVisible = childFeature =>
    !HIDDEN_FEATURES.includes(childFeature)

  return (
    <>
      <Section>Features</Section>
      {featuresData.allIds.map(id => {
        const feature = featuresData.byId[id]
        const isFeatureVisible = checkIfVisible(feature)
        const { children } = feature
        if (isFeatureVisible) {
          let rowContent
          if (children) {
            const sortedChildrenFeatures = children
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
            rowContent = systems.map(sys => {
              const childFeatures = sortedChildrenFeatures
                .filter(c => sys?.features.includes(c.id))
                .map(c => c.name)

              if (childFeatures.length > 0) {
                return (
                  <List key={sys.id}>
                    {childFeatures.map((v, i) => {
                      const isChildVisible = checkIfChildFeatureIsVisible(v)
                      return isChildVisible && <ListItem key={i}>{v}</ListItem>
                    })}
                  </List>
                )
              } else {
                return <Values key={sys.id}>None</Values>
              }
            })
          } else {
            rowContent = systems.map(s => (
              <Values key={s.id}>
                {s?.features.includes(id) ? 'Yes' : 'No'}
              </Values>
            ))
          }

          return (
            <React.Fragment key={id}>
              <Subsection>{feature.name}</Subsection>
              <Row>{rowContent}</Row>
            </React.Fragment>
          )
        } else {
          return null
        }
      })}
    </>
  )
}

export default Features
