import React from 'react'
import {styled} from '@material-ui/core/styles'

const Content = styled('div')({
})

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
})

const Message = styled('p')({
  fontSize: 16,
  fontWeight: 'normal',
  margin: '50px 0',
})

const Email = styled('span')(({theme}) => ({
  color: theme.palette.secondary.main,
  fontWeight: 'bold',
}))

const Sent = ({email, ...props}) => {
  return (
    <Content {...props}>
      <Title>Password Reset Sent</Title>
      <Message>Please check your email at <Email>{email}</Email> for instructions on how to finish resetting your password.</Message>
    </Content>
  )
}

export default Sent
