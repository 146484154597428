import {styled} from '@material-ui/core'

const Row = styled('div')(({theme}) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gridColumnGap: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}))

export default Row
