import produce from 'immer'
import {actionTypes} from './constants'

const initialState = {
  data: {
    allIds: [],
    byId: {},
  },
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LEADS_REQUESTED:
      draft.data.status = 'fetching'
      break

    case actionTypes.FETCH_LEADS_SUCCEEDED: {
      const leads = action.payload.results.data
      const allIds = new Set(draft.data.allIds)

      leads.forEach((lead) => {
        allIds.add(lead.id)
        draft.data.byId[lead.id] = lead
      })

      draft.data.allIds = Array.from(allIds)
      draft.data.total = action.payload.results.total
      if (action.payload.completed) draft.data.status = 'succeeded'

      break
    }

    case actionTypes.FETCH_LEADS_FAILED:
      draft.data.status = 'failed'
      break

    // no default
  }
}, initialState)

export default reducer
