import {styled} from '@material-ui/core'

export const Section = styled('div')(({theme}) => ({
  fontSize: 18,
  fontWeight: 500,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}))

export const Subsection = styled('div')(({theme}) => ({
  fontSize: 14,
  fontWeight: 500,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
}))
