import produce from 'immer'
import {userSchema} from 'users/store/validations'

export const initializeState = (user) => ({
  user,
  errors: validateUser(user),
  showErrors: [],
})

export const reducer = produce((draft, action) => {
  const {key, value} = action.payload || {}

  switch (action.type) {
    case 'UPDATE_FIELD':
      draft.user[key] = value
      draft.errors = validateUser(draft.user)
      break

    case 'SHOW_ERROR':
      const set = new Set(draft.showErrors)
      set.add(key)
      draft.showErrors = Array.from(set)
      break

    // no default
  }
})

const validateUser = (user) => {
  const errors = {}

  try {
    userSchema.validateSync(user, {abortEarly: false})
  } catch ({inner}) {
    inner.forEach(e => errors[e.path] = e.message)
  }

  return errors
}

export const updateField = (key, value) => ({
  type: 'UPDATE_FIELD',
  payload: {
    key,
    value,
  },
})

export const showError = (key) => ({
  type: 'SHOW_ERROR',
  payload: {
    key,
  },
})
