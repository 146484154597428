import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuthenticationState, useRegistrationState } from 'users/hooks'
import {
  Layout,
  Body,
  Info,
  Main,
  FeatureList
} from 'ui/components/DividedLayout'
import Signup from './Signup'
import SetPassword from './SetPassword'
import SwitchToLogin from './SwitchToLogin'

const Registration = props => {
  const { isAuthenticated } = useAuthenticationState()
  const { status } = useRegistrationState()

  if (isAuthenticated) return <Redirect to='/' />

  let component = <Signup {...props} />
  if (status === 'succeeded') component = <SetPassword {...props}/>

  return (
    <Layout>
      <Body>
        <Info>
          <FeatureList />
          <SwitchToLogin />
        </Info>
        <Main>{component}</Main>
      </Body>
    </Layout>
  )
}

export default Registration
