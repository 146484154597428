import React from 'react'
import {useDispatch} from 'react-redux'
import {
  styled,
  Button as MuiButton,
  Paper as MuiPaper,
} from '@material-ui/core'
import {openReportDialog} from 'reports/store/actions'
import {useCounts} from 'reports/hooks'
import Loader from 'ui/components/Loader'
import Counts from 'reports/components/Counts'

const Content = styled(MuiPaper)(({theme}) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
}))

const Header = styled('div')(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}))

const Title = styled('h4')(({theme}) => ({
  margin: 0,
  fontSize: 24,
  color: theme.palette.text.secondary,
}))

const Users = (props) => {
  const {users} = useCounts()
  const dispatchRedux = useDispatch()

  const openDialog = () => dispatchRedux(openReportDialog('users'))

  const content = users.total ? <Counts data={users}/> : <Loader/>

  return (
    <Content {...props}>
      <Header>
        <Title>Users</Title>
        <MuiButton size="small" color="primary" onClick={openDialog}>Generate Report</MuiButton>
      </Header>
      {content}
    </Content>
  )
}

export default Users
