import {LinearProgress as MuiLinearProgress} from "@material-ui/core"
import React from "react"
import {useSelector} from "react-redux"
import {getEditApplicationState} from "../../../store/selectors"

const FileUploadProgress = ({file}) => {
  const {data} = useSelector(getEditApplicationState)
  const fileUploadIndex = data.uploadingFiles?.findIndex(i => i.id === file?.id)
  if (fileUploadIndex >= 0){
    const loaded = data.uploadingFiles[fileUploadIndex].loaded
    return <MuiLinearProgress variant="determinate" value={(loaded / file?.size) * 100} />
  }else
    return <></>
}

export default FileUploadProgress