import React from 'react'
import { getData } from 'country-list'
import {
  FormControl as MuiFormControl,
  InputLabel as MuiInputLabel,
  MenuItem as MuiMenuItem,
  Select as MuiSelect
} from '@material-ui/core'
import { useLabelWidth } from 'ui/hooks'
import FormHelperText from '@material-ui/core/FormHelperText'

const CountrySelect = ({ value = '', onChange, error, errorMessage, ...props }) => {
  const [labelWidth, labelRef] = useLabelWidth()

  const countryData = getData().sort((a, b) => {
    if (a.code === 'US') return -1
    if (b.code === 'US') return 1
    return a.name.localeCompare(b.name)
  })

  return (
    <MuiFormControl required variant='outlined' margin='dense' error={error} {...props}>
      <MuiInputLabel ref={labelRef} htmlFor='country'>
        Country
      </MuiInputLabel>
      <MuiSelect
        name='country'
        value={value}
        onChange={onChange}
        labelWidth={labelWidth}
      >
        {countryData.map(d => (
          <MuiMenuItem key={d.code} value={d.code}>
            {d.name}
          </MuiMenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </MuiFormControl>
  )
}

export default CountrySelect
