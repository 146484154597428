import React from 'react'
import {
  styled,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core'

const Container = styled('div')(({theme, height}) => ({
  height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}))

const Loader = ({data, ...props}) => {
  if (data) return null

  return (
    <Container {...props}>
      <MuiCircularProgress/>
    </Container>
  )
}

export default Loader
