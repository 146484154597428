import React from 'react'
import {useDispatch} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {useAuthenticationState} from 'users/hooks'
import {logout} from 'users/store/actions'

const Logout = () => {
  const {status} = useAuthenticationState()
  const dispatchRedux = useDispatch()

  switch (status) {
    case 'authenticated':
    case 'authenticating':
      dispatchRedux(logout())
      return null

    default:
      return <Redirect to="/login"/>
  }
}

export default Logout
