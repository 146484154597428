import analyticsSagas from './sagas'

const getAnalyticsModule = () => ({
  id: 'analytics',
  reducerMap: {},
  sagas: [
    analyticsSagas,
  ],
  initialActions: [],
  finalActions: [],
})

export default getAnalyticsModule
