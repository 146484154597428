import produce from 'immer'

export const init = (userData={}) => {
  const {
    id,
    firstName,
    lastName,
    companyName,
    jobTitle,
    email,
    phone,
    applications,
    role = 'user',
    lastSignedIn,
    settings
  } = userData

  const user = {
    id,
    firstName,
    lastName,
    companyName,
    jobTitle,
    email,
    phone,
    applications,
    role,
    lastSignedIn,
    settings
  }

  return {
    user,
  }
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      draft.user[action.payload.name] = action.payload.value
      break

    // no default
  }
})

export const updateField = (name, value) => ({
  type: 'UPDATE_FIELD',
  payload: {
    name,
    value,
  },
})
