import React, {useRef, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as yup from 'yup'
import {styled} from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Link} from 'react-router-dom'
import {login} from 'users/store/actions'
import {useAuthenticationState} from 'users/hooks'

const emailSchema = yup.string().email().required()
const passwordSchema = yup.string().required()
const formSchema = yup.object().shape({
  email: emailSchema,
  password: passwordSchema,
})

const Content = styled('div')({
})

const ErrorMessage = styled('p')(({theme}) => ({
  color: theme.palette.error.main,
  margin: 0,
  padding: 5,
}))

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const Actions = styled('div')({
  marginTop: 24,
  display: 'flex',
  justifyContent: 'space-between',
})

const LoginForm = (props) => {
  const showAuthError = useRef(false)
  const {status} = useAuthenticationState()
  const dispatchRedux = useDispatch()

  const emailFieldRef = useRef(null)
  const passwordFieldRef = useRef(null)
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [showFieldErrors, setShowFieldErrors] = useState(false)

  const emailValid = emailSchema.isValidSync(email)
  const passwordValid = passwordSchema.isValidSync(password)

  const handleEmailChange = (event) => {
    const {value} = event.target

    setEmail(value)

    if (formSchema.isValidSync({email: value, password})) {
      setShowFieldErrors(false)
      showAuthError.current = false
    }
  }

  const handlePasswordChange = (event) => {
    const {value} = event.target

    setPassword(value)

    if (formSchema.isValidSync({email, password: value})) {
      setShowFieldErrors(false)
      showAuthError.current = false
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      switch (event.target) {
        case emailFieldRef.current:
          passwordFieldRef.current.focus()
          break

        case passwordFieldRef.current:
          handleSubmit(event)
          break

        // no default
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!emailValid || !passwordValid) {
      setShowFieldErrors(true)
    } else {
      const credentials = {strategy: 'local', email, password}
      dispatchRedux(login(credentials))
    }
  }

  let errorText
  if (status === 'failed' && showAuthError.current) {
    errorText = "The email and password you entered did not match our records. Please double-check and try again."
  }

  if (showFieldErrors && (!emailValid || !passwordValid)) {
    errorText = "Please enter a valid email and password."
  }

  let errorMessage
  if (errorText) {
    errorMessage = <ErrorMessage>{errorText}</ErrorMessage>
  }

  const disabled = status === 'authenticating'

  showAuthError.current = true
  return (
    <Content>
      {errorMessage}
      <Form onSubmit={handleSubmit} {...props}>
        <TextField
          label="Email"
          type="email"
          defaultValue={email}
          onChange={handleEmailChange}
          onKeyPress={handleKeyPress}
          variant="outlined"
          margin="dense"
          fullWidth
          autoComplete="email"
          autoFocus
          inputRef={emailFieldRef}
          error={showFieldErrors && !emailValid}
        />
        <TextField
          label="Password"
          type="password"
          defaultValue={password}
          onChange={handlePasswordChange}
          onKeyPress={handleKeyPress}
          variant="outlined"
          margin="dense"
          fullWidth
          autoComplete="current-password"
          inputRef={passwordFieldRef}
          error={showFieldErrors && !passwordValid}
        />
      </Form>
      <Actions>
        <Button
          color="secondary"
          component={Link}
          to="/password"
        >
          Forgot Your Password
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          disabled={disabled}
        >
          Sign In
        </Button>
      </Actions>
    </Content>
  )
}

export default LoginForm
