import {actionTypes} from './constants'

export const fetchCounts = () => ({
  type: actionTypes.FETCH_COUNTS_REQUESTED,
})

export const openReportDialog = (reportType) => ({
  type: actionTypes.OPEN_REPORT_DIALOG,
  payload: {
    reportType,
  },
})

export const closeReportDialog = () => ({
  type: actionTypes.CLOSE_REPORT_DIALOG,
})

export const setReportOptions = (options) => ({
  type: actionTypes.SET_REPORT_OPTIONS,
  payload: {
    options,
  },
})

export const generateReportData = () => ({
  type: actionTypes.GENERATE_REPORT,
})
