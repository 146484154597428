import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import MuiTextField from '@material-ui/core/TextField'
import { useCurrentSearchFilters } from 'searches/hooks'
import { updateCurrentSearchFilters } from 'searches/store/actions'
import { useCookies } from 'react-cookie'
import { THIRTY_DAYS } from 'constants/timeIntervals'
import { useCurrentUser } from 'users/hooks'

const Keywords = props => {
  const { keywords } = useCurrentSearchFilters()
  const dispatchRedux = useDispatch()
  const user = useCurrentUser()
  const isGuestUser = !user
  const [timeInt, setTimeInt] = useState(null)
  const [cookies, setCookie] = useCookies(['guestSearchCount'])

  const updateGuestSearchCount = e => {
    // 5 seconds (1000 = 1 second)
    const TIME_OUT = 5000
    const searchValue = e.target.value
    clearTimeout(timeInt)
    setTimeInt(
      setTimeout(() => {
        if (searchValue) {
          const guestSearchCount = cookies.guestSearchCount || 0
          setCookie(
            'guestSearchCount',
            Number(guestSearchCount) + 1,
            { path: '/' },
            THIRTY_DAYS
          )
        }
      }, TIME_OUT)
    )
  }

  const handleChange = e => {
    if (isGuestUser) {
      updateGuestSearchCount(e)
    }
    dispatchRedux(updateCurrentSearchFilters({ keywords: e.target.value }))
  }

  return (
    <MuiTextField
      label='Keywords'
      type='text'
      value={keywords || ''}
      onChange={handleChange}
      variant='outlined'
      margin='dense'
      fullWidth
    />
  )
}

export default Keywords
