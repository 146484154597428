import React from 'react'
import {
  styled,
  FormControl as MuiFormControl,
  MenuItem as MuiMenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
} from '@material-ui/core'

const FormControl = styled(MuiFormControl)(({theme}) => ({
  marginRight: theme.spacing(2),
  width: 160,
}))

const SortBy = ({value, setValue, ...props}) => {
  const input = <MuiOutlinedInput id="sort-by" name="sort-by" />

  return (
    <FormControl variant="outlined" margin="dense">
      <MuiSelect
        name="sort-by"
        value={value}
        onChange={e => setValue(e.target.value)}
        input={input}
      >
        <MuiMenuItem value="lastName">Sort by Name</MuiMenuItem>
        <MuiMenuItem value="recentSignIn">Sort by Sign In (recent - last)</MuiMenuItem>
        <MuiMenuItem value="lastSignIn">Sort by Sign In (last - recent)</MuiMenuItem>
      </MuiSelect>
    </FormControl>
  )
}

export default SortBy
