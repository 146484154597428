import {useSelector, useDispatch} from 'react-redux'
import {getCounts, getReportData} from 'reports/store/selectors'
import {fetchCounts} from 'reports/store/actions'

export const useCounts = () => {
  const counts = useSelector(getCounts)
  const dispatchRedux = useDispatch()

  if (!counts.fetchedAt) dispatchRedux(fetchCounts())

  return counts
}

export const useReportData = () => {
  const data = useSelector(getReportData)

  return data
}
