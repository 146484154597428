import mirrorCreator from 'mirror-creator'

export const actionTypes = mirrorCreator([
  'RESET_COUNTS',
  'FETCH_COUNTS_REQUESTED',
  'FETCH_COUNTS_SUCCEEDED',
  'REPORT_DATA_LOADED',

  'FETCH_REPORT_DATA_REQUESTED',
  'FETCH_REPORT_DATA_SUCCEEDED',

  'OPEN_REPORT_DIALOG',
  'CLOSE_REPORT_DIALOG',

  'SET_REPORT_OPTIONS',
  'REPORT_UPDATED',
], {prefix: 'reports/'})
