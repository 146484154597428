import feathers from '@feathersjs/client'
import io from 'socket.io-client'

const ioOptions = {
  upgrade: false,
  transports: ['websocket'],
}

let socket
if (process.env.REACT_APP_API_URI) {
  socket = io(process.env.REACT_APP_API_URI, ioOptions)
} else {
  socket = io(ioOptions)
}

const client = feathers()

client.configure(feathers.socketio(socket, {timeout: 15000}))
client.configure(feathers.authentication({
  storage: localStorage,
}))

export default client
