import produce from 'immer'
import {actionTypes, initialFilters} from './constants'

const initialState = {
  currentSearch: {
    id: null,
    filters: initialFilters,
    applications: null,
  },
  data: {
    allIds: [],
    byId: {},
  },
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CURRENT_SEARCH_FILTERS_COMPLETED:
      draft.currentSearch.id = action.payload.id
      draft.currentSearch.filters = Object.assign(draft.currentSearch.filters, action.payload.filters)
      break

    case actionTypes.UPDATE_CURRENT_SEARCH_RESULTS_COMPLETED:
      draft.currentSearch.applications = action.payload.applications
      break

    case actionTypes.FETCH_SEARCHES_REQUESTED:
      draft.data.status = 'fetching'
      break

    case actionTypes.FETCH_SEARCHES_SUCCEEDED: {
      const searches = action.payload.results.data
      const allIds = new Set(draft.data.allIds)

      searches.forEach((search) => {
        allIds.add(search.id)
        draft.data.byId[search.id] = search
      })

      draft.data.allIds = Array.from(allIds)
      draft.data.total = action.payload.results.total
      if (action.payload.completed) draft.data.status = 'succeeded'

      break
    }

    case actionTypes.FETCH_USERS_FAILED:
      draft.data.status = 'failed'
      break

    // no default
  }
}, initialState)

export default reducer
