import React from 'react'
import {Provider} from 'react-redux'
import {ConnectedRouter} from 'connected-react-router'
import {ThemeProvider} from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {CookiesProvider} from 'react-cookie'
import MomentUtils from '@date-io/moment'
import store from 'app/store'
import history from 'utils/history'
import theme from './theme'
import Routes from './Routes'

const App = () => {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CssBaseline />
            <CookiesProvider>
              <Routes />
            </CookiesProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  )
}

export default App
