import React from 'react'
import {styled} from '@material-ui/core/styles'

const Content = styled('div')({
  marginTop: 10,
})

const Footer = ({children, ...props}) => {
  return (
    <Content {...props}>
      {children}
    </Content>
  )
}

export default Footer
