import React, {useState} from 'react'
import {
  styled,
} from '@material-ui/core/styles'

const Content = styled('p')({
  margin: 0,
  marginBottom: 10,
})

const ExpandLink = styled('a')(({theme}) => ({
  display: 'inline-block',
  color: theme.palette.primary.main,
  cursor: 'pointer',

  '&:hover': {
    textDecoration: 'underline',
    transitionDuration: theme.transitions.duration.standard,
  }
}))

const Description = ({description, ...props}) => {
  const [expanded, setExpanded] = useState(false)

  if (!description) return null

  const isLong = description.length > 200

  let shortDescription = description.substring(0, 199)

  const text = expanded ? description : shortDescription

  const handleExpand = (event) => {
    event.stopPropagation()
    setExpanded(!expanded)
  }

  let ellipsis, expandLink
  if (isLong) {
    const label = expanded ? "Read Less" : "Read More"
    expandLink = <ExpandLink onClick={handleExpand}>{label}</ExpandLink>
    ellipsis = expanded ? <span> </span> : <span>&hellip; </span>
  }

  return (
    <Content {...props}>
      {text}
      {ellipsis}
      {expandLink}
    </Content>
  )
}

export default Description
