import React from 'react'
import {Switch, Route} from 'react-router-dom'
import List from './List'
import Edit from './Edit'

const Admin = (props) => {
  return (
    <Switch>
      <Route path="/users/new" component={Edit} exact/>
      <Route path="/users/:id/edit" component={Edit} exact/>
      <Route component={List}/>
    </Switch>
  )
}

export default Admin
