import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiGrid from '@material-ui/core/Grid'

const DividerPanel = styled('div')(({theme}) => ({
  height: '100%',

  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: 36,
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.divider,
    padding: '0 20px',
  },
}))

const Main = ({children, ...props}) => {
  return (
    <MuiGrid item xs={12} md={8} lg={7} {...props}>
      <DividerPanel>
        {children}
      </DividerPanel>
    </MuiGrid>
  )
}

export default Main
