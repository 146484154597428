import React from 'react'
import {
  styled,
} from '@material-ui/core'

const Content = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

const Value = styled('div')(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 36,
}))

const Name = styled('div')(({theme}) => ({
  fontSize: 18,
}))

const Count = ({name, value, ...props}) => {
  if (value === undefined) return null

  return (
    <Content {...props}>
      <Value>{value}</Value>
      <Name>{name}</Name>
    </Content>
  )
}

export default Count
