import searchesReducer from './reducer'
import rootSaga from "./sagas"

const getSearchesModule = () => ({
  id: 'searches',
  reducerMap: {
    searches: searchesReducer,
  },
  sagas: [
    rootSaga,
  ],
  initialActions: [],
  finalActions: [],
})

export default getSearchesModule
