import {useSelector, useDispatch} from 'react-redux'
import {getMode} from 'app/store/selectors'
import {setMode} from 'app/store/actions'

export const useMode = () => {
  const mode = useSelector(getMode)
  const dispatch = useDispatch()

  const dispatchSetMode = mode => dispatch(setMode(mode))

  return [mode, dispatchSetMode]
}
