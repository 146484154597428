import React from 'react'
import {styled} from '@material-ui/core/styles'
import MuiPaper from '@material-ui/core/Paper'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

const Content = styled(MuiPaper)({
  flex: '1 1 auto',
  padding: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const LoadingText = styled('h2')({
  margin: 0,
  marginLeft: 20,
  fontWeight: 500,
})

const Loader = () => {
  return (
    <Content>
      <MuiCircularProgress disableShrink />
      <LoadingText>Calculating</LoadingText>
    </Content>
  )
}

export default Loader
