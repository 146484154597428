import { actionTypes } from './constants'

export const login = credentials => ({
  type: actionTypes.LOGIN_REQUESTED,
  payload: {
    credentials
  }
})

export const logout = () => ({
  type: actionTypes.LOGOUT_REQUESTED
})

export const createUser = (user, isUserRegistering) => ({
  type: actionTypes.CREATE_USER_REQUESTED,
  payload: {
    user,
    isUserRegistering
  }
})

export const resetUserState = () => ({
	type: actionTypes.RESET_USER_STATE,
})

export const sendPasswordReset = (email, notifierOptions) => ({
  type: actionTypes.SEND_PASSWORD_RESET,
  payload: {
    value: { email },
    notifierOptions
  }
})

export const sendInviteEmail = id => ({
  type: actionTypes.SEND_INVITE_EMAIL,
  payload: {
    id
  }
})

export const updatePassword = ({ password, token, email, oldPassword }) => ({
  type: actionTypes.UPDATE_PASSWORD_REQUESTED,
  payload: {
    password,
    token,
    email,
    oldPassword
  }
})

export const addLmsToList = ids => ({
  type: actionTypes.ADD_LMS_TO_LIST,
  payload: ids
})

export const removeLmsFromList = id => ({
  type: actionTypes.REMOVE_LMS_FROM_LIST,
  payload: id
})

export const refetchCurrentUser = () => ({
  type: actionTypes.REFETCH_CURRENT_USER_REQUESTED
})

export const updateCurrentUser = (user, message) => ({
  type: actionTypes.UPDATE_CURRENT_USER_REQUESTED,
  payload: {
    user,
    message
  }
})

export const fetchUsers = ({ limit = 50, skip = 0 } = {}) => ({
  type: actionTypes.FETCH_USERS_REQUESTED,
  payload: {
    limit,
    skip
  }
})

export const patchUser = user => ({
  type: actionTypes.PATCH_USER_REQUESTED,
  payload: {
    user
  }
})

export const removeUser = id => ({
  type: actionTypes.REMOVE_USER_REQUESTED,
  payload: {
    id
  }
})

export const handleCookieConsent = ({ ipAddress, method, accepted }) => ({
  type: actionTypes.UPDATE_GUEST_COOKIE_CONSENT,
  payload: {
    ipAddress,
				method,
				accepted
  }
})
