import React from 'react'
import {
  styled,
  Paper as MuiPaper,
} from '@material-ui/core'
import {useUsers} from 'users/hooks'
import Form from './Form'

const Paper = styled(MuiPaper)({
  marginTop: 20,
  padding: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
})

const Edit = ({match, ...props}) => {
  const {id} = match.params
  const {data} = useUsers()

  let userData
  if (id) {
    if (!data || !data.byId[id]) return null

    userData = data.byId[id]
  }

  return (
    <Paper square>
      <Form userData={userData}/>
    </Paper>
  )
}

export default Edit
