import React from 'react'
import {
  styled,
  FormControl as MuiFormControl,
  MenuItem as MuiMenuItem,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
} from '@material-ui/core'

const FormControl = styled(MuiFormControl)(({theme}) => ({
  marginRight: theme.spacing(2),
  width: 125,
}))

const RoleFilter = ({role, setRole, ...props}) => {
  const roleInput = <MuiOutlinedInput id="role" name="role"/>

  return (
    <FormControl variant="outlined" margin="dense">
      <MuiSelect
        name="role"
        value={role}
        onChange={e => setRole(e.target.value)}
        input={roleInput}
      >
        <MuiMenuItem value="all">All Roles</MuiMenuItem>
        <MuiMenuItem value="admins">Admins</MuiMenuItem>
        <MuiMenuItem value="vendors">Vendors</MuiMenuItem>
        <MuiMenuItem value="users">Users</MuiMenuItem>
      </MuiSelect>
    </FormControl>
  )
}

export default RoleFilter
