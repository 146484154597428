import React, {useRef} from 'react'
import {Redirect} from 'react-router-dom'
import {styled} from '@material-ui/core/styles'
import {
  Layout,
  Body,
  Info,
  Main,
} from 'ui/components/DividedLayout'
import {useAuthenticationState} from 'users/hooks'
import LoginForm from './LoginForm'
import SwitchToRegistration from './SwitchToRegistration'

const Title = styled('h2')({
  fontSize: 24,
  marginTop: 12,
  marginBottom: 24,
})

const Login = (props) => {
  const initialRender = useRef(true)
  const {status} = useAuthenticationState()

  const from = props.location.state ? props.location.state.from : "/"

  if (status === 'authenticated') return <Redirect to={from}/>
  if (status === 'authenticating' && initialRender.current) return null

  initialRender.current = false

  return (
    <Layout>
      <Body>
        <Info>
          <SwitchToRegistration/>
        </Info>
        <Main>
          <Title>Sign in</Title>
          <LoginForm/>
        </Main>
      </Body>
    </Layout>
  )
}

export default Login
