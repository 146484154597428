import React from 'react'
import MuiCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const FeatureCheckbox = ({ checked, handleChange, feature, isStandAlone }) => {
  const { name } = feature
  return (
    <FormControlLabel
      style={{ marginLeft: 0 }}
      control={
        <MuiCheckbox
          icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
          checkedIcon={<CheckBoxIcon fontSize='small' />}
          checked={checked}
          onChange={() => handleChange({ feature })}
          name={name}
          color='primary'
        />
      }
      label={<span style={{ fontSize: '14px' }}>{name}</span>}
    />
  )
}

export default FeatureCheckbox
