import produce from 'immer'

export const initialState = {
  open: false,
  index: 0,
}

export const reducer = produce((draft, action) => {
  switch (action.type) {
    case 'OPEN':
      draft.open = true
      draft.index++
      break

    case 'CLOSE':
      draft.open = false
      break

    // no default
  }
})
